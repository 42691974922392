@import (reference) "../../styles/main";

.Switch {
  display: flex;
  justify-content: flex-start;
  &__input {
    width: 0;
    height: 22px;
    z-index: -1;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    &:disabled {
      & + .Switch__label {
        cursor: not-allowed;
      }
    }
    &:checked + .Switch__label {
      .Switch__label__slide {
        &::before {
          opacity: 0.5;
        }
        &::after {
          opacity: 1;
        }
      }
      .Switch__label__toggle {
        top: 4px;
        left: 19px;
      }
    }

    &:disabled + .Switch__label {
      .Switch__label__slide {
        background-color: @color-coolGray-600;
      }
      .Switch__label__toggle {
        background-color: #dcdce0;
        border-color: #bab9c1;
      }
    }
  }

  &__label {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    &__slide {
      width: 36px;
      height: 20px;
      border-radius: 20px;
      background-color: #e5e7eb;
      position: relative;
      transition: @transition-all;

      &--enabled {
        background-color: #594ab6;
      }
    }
    &__toggle {
      position: absolute;
      left: 2px;
      top: 4px;
      width: 15px;
      height: 15px;
      border-radius: 15px;
      background-color: @color-white;
      z-index: 2;
      transition: @transition-all;
    }
    &__text {
      padding-left: 12px;
      color: #374151;
    }
  }
}
