.PlatformHome {
    h3 {
        font-size: 1.5rem;
        font-weight: 500;
        margin-right: 10px;
    }

    a {
        color: inherit;
    }

    .small-logo {
        width: 20px;
        height: 20px;
        border-radius: 10px;
    }

    &__highlight {
        margin-top: 0 !important;
        &__item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        padding: 30px;
        font-size: 1.375rem;

            &__value {
                color: @color-primary-900;
            }
        }
    }

    &__recent-activity {
        display: flex;
        gap: 15px;
        margin-bottom: 30px;

        img {
            height: 30px;
            width: 30px;
            border-radius: 15px;
        }

        p {
            margin-bottom: 0;
            font-weight: 500;
        }

        span {
            font-size: 0.9rem;
            font-weight: 300;
            &.capitalize {
                text-transform: capitalize;
            }
        }

        .details {
            font-size: 0.9rem;
            font-weight: 400;
        }
    &__container {
        padding: 30px;
        overflow-y: auto;
    }
    }

    &__radio-container {
        display: flex;
        gap: 10px;
        flex-direction: column;
        width: 200px;
    }

    &__link {
        color: @color-blue-900;
        font-size: 1rem;
        margin-left: auto;
        font-weight: 600;
        padding: 30px;
        display: flex;
        justify-content: flex-end;
        a {
            display: flex;
            align-items: center;
        }
    }

    &__typeCards {
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 30px;
        width: 100%;
        .bp-phone ({
            flex-direction: column;
            gap: 0;
        });
    }

    &__active-filters {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        gap: 5px 10px;
        padding: 30px;
        white-space: nowrap;
        width: 100%;

        span {
            background-color: #e5e5e5;
            border-radius: 20px;
            padding: 5px 10px;
            white-space: nowrap;

            button {
                background-color: transparent;
                cursor: pointer;
                border: none;
            }
        }
    }

    &__card {
        overflow-y: none;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
        border-radius: 12px;
        margin-top: 40px;

        &__header {
            display: flex;
            justify-content: space-between;
            padding: 30px;
            border-bottom: 1px solid var(--coolGray-200);

            &__title {
                display: flex;
            }

            &__control {
                display: flex;
                gap: 10px;

                .group {
                    background-color: var(--coolGray-300);
                    border-radius: 6px;
                }

                button {
                    padding: 0 8px;
                    min-height: 30px;
                    height: 30px;

                    &.disabled {
                        background-color: var(--coolGray-300);
                        color: var(--coolGray-500);
                        box-shadow: none;
                        border: none;
                    }
                }
            }
        }
    }

    &__tooltip {
        padding: 20px;
        background-color: white;
    }

    &__loader,
    &__empty {
        height: 60px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        & .Loader {
            width: 40px;
            height: 40px;
        }
    }

    &__cell {

        &--status,
        &--transactions,
        &--errors {
            text-align: right;
            width: 100%;
        }

        &--download-container {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        &--download {
            font-size: @text-size-14;
            font-weight: 400;

            svg {
                stroke-width: 2.5;
            }
        }

        &--nameSvg {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            min-width: 32px;
            height: 32px;
            border-radius: 32px;
            background-color: #C2F4ED;
            margin-right: 8px;

            &>svg {
                stroke: #64CCB9;
                stroke-width: 2.5;
            }
        }

        &--nameString {
            text-overflow: ellipsis;
            width: 100%;
            overflow: hidden;
        }
    }

    &__table {
        min-width: 600px;
        max-width: 100%;
        grid-template-columns:
            50px minmax(100px, 2fr) minmax(100px, 2fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(50px, 1fr) minmax(50px, 1fr);

        &__show {
            grid-column: 1 / -1;
            display: flex;
            justify-content: center;
        }

        & thead th {
            min-height: 43px;

            &:first-of-type {
                padding-left: 30px;
            }

            &:last-of-type {
                padding-right: 30px;
                border-right: none;
            }
        }

        & tr>td {
            border-bottom: 1px solid #e5e5e5;
            color: #4b5563;
            font-size: @text-size-14;

            &:first-of-type {
                padding-left: 30px;
                font-weight: 600;
                color: #111827;
                font-size: @text-size-16;
                line-height: 1.5;
            }

            &:last-of-type {
                padding-right: 30px;
                border-right: none;
            }
        }

        & tr {
            &:hover {
                >td {
                    background-color: #f0eff9;
                }
            }
        }

        & td {
            min-height: 48px !important;
        }

        &__total {
            width: 100%;
            text-align: right;
        }

        &__checkbox {
            div>input:enabled:checked {
                background-image: url(/_next/static/media/checkbox-checked.7e7bd052.7e7bd052.svg);
                background-color: var(--blue-700);
            }
        }

        &__connector {
            display: flex;
            align-items: center;
            gap: 15px;

            img {
                height: 30px;
                width: 30px;
                border-radius: 15px;
            }

            p {
                margin-bottom: 0;
            }

            span {
                font-size: 0.9rem;
                font-weight: 300;
            }

            &__data {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;
            }
        }
    }

    &__space {
        display: flex;
        gap: 10px;
    }
}
