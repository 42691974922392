@import (reference) "../../../../../styles/main";

.UseCaseTables {
  overflow-y: auto;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  background-color: @color-white;
  &__cell {
    &--typeName {
      color: #6b7280;
      font-weight: 400;
      font-size: @text-size-12;
      margin-top: 5px;
      display: flex;
      align-items: center;

      img {
        margin-right: 5px;
      }
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  &__search {
    flex: 1 1 700px;
    max-width: calc(100% - 145px);
    input {
      height: 40px;
    }
    & > div {
      max-width: 700px;
    }

    & > :first-child {
      & > div {
        & > svg {
          height: 20px;
          width: 20px;
        }
      }
    }
  }
  &__table {
    min-width: 600px;
    grid-template-columns: minmax(min-content, 1fr) minmax(min-content, 1fr);
    &.long {
      grid-template-columns: minmax(min-content, 1fr) minmax(min-content, 1fr)
    }
    &__container {
      width: 100%;
    }
    &__button {
      border: none;
      outline: none;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #17009a;
      font-size: @text-size-14;
      height: 52px;
      width: 100%;
      cursor: pointer;
    }
    & thead th {
      min-height: 43px;
      &:first-of-type {
        padding-left: 30px;
      }

      &:last-of-type {
        padding-right: 30px;
        border-right: none;
      }
    }
    & tr > td {
      border-bottom: 1px solid #e5e5e5;
      color: #4b5563;
      font-size: @text-size-14;

      &:first-of-type {
        padding-left: 30px;
        font-weight: 600;
        color: #111827;
        font-size: @text-size-16;
      }

      &:last-of-type {
        padding-right: 30px;
        border-right: none;
      }
    }
    & tr {
      &:hover {
        > td {
          background-color: #f0eff9;
        }
      }
    }
    & td {
      min-height: 55px;
    }
    & code {
      font-size: @text-size-14;
    }
    &__description {
      white-space: break-spaces;
    }
    &__connector {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      height: 24px;
      padding: 0 8px;
      color: #372fa2;
      font-size: @text-size-14;
      background-color: #e0e8ff;

      > svg {
        stroke: #828df8;
        stroke-width: 3px;
        margin-right: 4px;
      }
    }
  }
  &__combinations {
    padding: 20px;
    background: @color-white;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    max-width: 530px;
    height: 100%;
    .bp-desktop-small({
      margin-top: 0;
    });
    .bp-desktop-big({
      max-width: unset;
    });
    &__title {
      color: #4b5563;
      font-size: @text-size-14;
      font-weight: 600;
      margin-bottom: 16px;
    }
    &__header {
      font-weight: 400;
      color: #4b5563;
      font-size: @text-size-14;
      margin-bottom: 16px;
    }
  }
  &__requiredInputSets {
    background-color: #f1f1f7;
    border: 1px solid #e5e7eb;
    border-radius: 4px;
    padding-bottom: 12px;

    &__multipleContainer {
      border: 1px solid #e5e7eb;
      border-radius: 4px;
      &:not(:last-of-type) {
        margin-bottom: 8px;
      }
      .UseCaseTables__requiredInputSets {
        border: none;
        border-top: 1px solid #e5e7eb;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }

    &__multipleButton {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      background-color: #f9fafb;
      border: none;
      outline: none;
      padding: 8px 16px;
      cursor: pointer;

      &__title {
        display: flex;
        align-items: center;

        &__name {
          color: #4b5563;
          font-weight: 600;
          font-size: @text-size-14;
        }

        > img, > span > img {
          width: 24px;
          height: 24px;
          border-radius: 24px;
          margin-right: 12px;
        }

        > span {
          margin-right: 12px !important;
        }
      }

      > svg {
        stroke: #9b92d2;
        stroke-width: 2px;
      }
    }
  }
  &__requiredInputSet {
    &__wrapper {
      position: relative;
      height: fit-content;
      border-radius: 2px;
      padding: 12px 0 0 52px;
      display: flex;
      flex-wrap: wrap;

      &:before {
        position: absolute;
        content: "";
        width: 12px;
        min-width: 12px;
        height: 12px;
        border: 2px solid #c7c3e4;
        border-radius: 3px;
        top: 16px;
        left: 22px;
      }
    }
    &__item {
      position: relative;
      text-overflow: ellipsis;
      color: #4b5563;
      font-size: @text-size-14;
      font-weight: 400;
      margin-right: 26px;
      &:not(:last-child):after {
        position: absolute;
        content: "+";
        font-weight: 900;
        width: 12px;
        min-width: 12px;
        height: 12px;
        top: 0;
        right: -19px;
      }
    }
  }
}
