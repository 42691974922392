@import (reference) "../../../styles/main";

.DataCatalogItem {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid transparent;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  transition: all 0.3s;
  padding: 24px 20px;

  &:hover,
  &:focus {
    cursor: pointer;
    border: 1px solid #78f5de;
    background-color: #ebfdfa;
    transition: all 0.3s;
  }

  &__header {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logos {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__recipeImage {
    width: 28px;
    height: 28px;
    border-radius: 28px;
    margin-right: -10px;
    border: 2px solid #ffffff;
    background-color: #ffffff;
    overflow: hidden;

    img {
      max-width: 100%;
      max-height: 100%;
      border-radius: 28px;
    }

    &.counter {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #4b5563;
      background-color: #e5e7eb;
      font-size: @text-size-12;
      line-height: @text-size-12;
    }
  }

  &__connectorImage {
    width: fit-content;
    height: 40px;
    border-radius: 20px;
  }

  &__source {
    margin-left: 8px;
    font-size: @text-size-14;
    line-height: 1.4;
    color: #6b7280;
  }

  &__label {
    color: #372fa2;
    font-size: @text-size-14;
    line-height: 1.4;
    padding: 2px 8px;
    border-radius: 12px;
    background-color: #e0e8ff;
  }

  &__name {
    margin-bottom: 16px;
    font-size: @text-size-16;
    line-height: 1.5;
    font-weight: 600;
    color: #111827;
  }

  &__description {
    margin-bottom: 20px;
    font-size: @text-size-14;
    line-height: 1.4;
    color: #4b5563;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

    em {
      font-style: italic;
    }
  }

  &__tags {
    margin-top: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }

  &__tag {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 6px;
    border-radius: 20px;
    width: fit-content;
    background-color: #f3f4f6;
    color: #374151;
    font-size: @text-size-14;
    line-height: 1.4;

    svg {
      stroke: #9ca3af;
      stroke-width: 3px;
      margin-right: 4px;
    }

    img {
      max-height: 18px;
    }
  }

  &__tagButton {
    display: flex;
    border: none;
    outline: none;
    align-items: center;
    justify-content: center;
    padding: 3px 6px;
    border-radius: 20px;
    width: fit-content;
    background-color: #f3f4f6;
    color: #374151;
    font-size: @text-size-14;
    line-height: 1.4;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      background-color: #e0e8ff;
      transition: all 0.2s;
    }
  }

  &__logoContainer {
    display: flex;
    justify-content: center;
    height: 18px;
    width: 18px;
    border-radius: 18px;
    margin-right: 4px;
    overflow: hidden;
  }

  &__awsTag {
    position: absolute;
    right: 0;
    top: 10px;
    background-color: #252f3e;
    transform: translateX(20px) rotate(45deg);
    display: flex;
    align-items: center;
    padding: 5px 30px;

    img {
      width: 20px;
    }
  }

  .ApplicableRegulationsTags {
    margin-left: auto;
  }
}
