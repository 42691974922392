@import (reference) "../../styles/main";

.UseCaseOverviewPage {
  &__fetching {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  &__error {
    width: 100%;
  }
  &__pricing {
    min-width: 480px;
    width: 480px;
    margin-bottom: 32px;

    .bp-desktop-big({
      min-width: 430px;
      width: 430px;
    });

    .bp-desktop-small({
      width: 100%;
      max-width: 100%;
      margin-bottom: 30px;
      min-width: unset;
    });
  }
  &__connectorsInRecipe {
    display: grid;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    grid-template-columns: repeat(4, minmax(320px, 1fr));
    grid-auto-rows: minmax(56px, fit-content);
    .bp-desktop-large({
      grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    });
    .MotionElementPopupZIndex {
      width: fit-content;
    }
  }
  &__sectionTitle {
    color: #4b5563;
    font-weight: 600;
    margin-bottom: 8px;
  }
  &__badge {
    & > :nth-child(1n) {
      & > img {
        width: 18px;
        height: 18px;
        max-width: 18px;
        border-radius: 18px;
      }

      & > svg {
        stroke: #b2abdb;
        stroke-width: 3px;
        width: 16px;
      }
    }
    & > :nth-child(2n) {
      color: #594ab6;
    }
  }
  &__connectors {
    margin-bottom: 32px;
    padding: 32px 0;
    border-bottom: 1px solid #e5e7eb;
    border-top: 1px solid #e5e7eb;

    &__item {
      display: flex;
      padding: 8px;
      border-radius: 4px;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
      border: 1px solid transparent;
      transition: all 0.3s;

      &:hover,
      &:focus {
        cursor: pointer;
        border: 1px solid #78f5de;
        background-color: #ebfdfa;
        transition: all 0.3s;
      }

      &__delete {
        margin-left: auto;
        align-self: center;
        padding: 4px;
        line-height: 1rem;
        border: none;
        outline: none;
        background-color: transparent;
        cursor: pointer;
        > svg {
          stroke-width: 2.5;
          stroke: #6b7280;
        }
        &:hover {
          > svg {
            stroke: #d91c5c;
          }
        }
      }
    }
    &__logoContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 6px;

      > img,
      > span > img {
        width: fit-content;
        height: 32px;
        border-radius: 32px;
      }
    }

    &__textContainer {
      font-weight: 400;

      &__name {
        color: #111827;
        font-size: @text-size-16;
        font-weight: 600;
        line-height: 1.5;
      }

      &__connector {
        color: #6b7280;
        font-size: @text-size-12;
        line-height: 1.3;
      }
    }
  }
  &__top {
    display: flex;
    justify-content: space-between;
    gap: 64px;
    margin-bottom: 40px;
    .bp-desktop-big({
      flex-direction: column;
    });
  }
  &__overview {
    width: calc(100% - 624px);
    .bp-desktop-big({
      width: 100%;
    });
    &__header {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
    }
    &__title {
      font-size: @text-size-22;
      font-weight: 600;
    }
  }
  &__quickstart {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    padding: 32px;
    width: 100%;
    max-width: 560px;
    background: @color-white;
    &__title {
      font-size: @text-size-22;
      font-weight: 600;
      margin-bottom: 16px;
    }
    &__paragraph {
      margin-bottom: 20px;
      span {
        color: #8479c7;
        font-size: @text-size-13;
        font-family: @font-family-mono;
      }
      a:hover {
        text-decoration: underline;
      }
    }
    &__codeblock {
      width: 100%;
      height: 330px;
      background: #111827;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      margin-bottom: 16px;
    }
    &__actions {
      display: flex;
      gap: 16px;
    }
  }
  &__details {
    margin-bottom: 40px;
    &__title {
      font-weight: 600;
      font-size: @text-size-22;
      margin-bottom: 34px;
    }
    &__description {
      font-weight: 600;
      font-size: @text-size-16;
      color: #4b5563;
      margin-bottom: 8px;
    }
    &__paragraph {
      margin-bottom: 34px;
    }
    &__info {
      display: flex;
      flex-direction: column;
      &__position {
        display: flex;
        align-items: center;
        min-height: 32px;
        margin-bottom: 16px;
      }
      &__label {
        min-width: 120px;
        font-weight: 600;
        color: #4b5563;
      }
    }
  }
  &__type {
    height: 32px;
    border-radius: 32px;
    font-weight: 400;
    font-size: @text-size-16;
    line-height: 24px;
    padding: 4px 12px;
    &--evaluation {
      color: #4f9e90;
      background-color: #c2f4ed;
    }
    &--production {
      color: #854d0e;
      background-color: #fef9c3;
    }
  }
}
